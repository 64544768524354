@font-face {
    font-family: 'Astoria';
    font-weight: 300;
    src: url('./fonts/AstoriaEXTRALIGHT.ttf') format('truetype');
}
@font-face {
    font-family: 'Astoria';
    font-weight: 400;
    src: url('./fonts/AstoriaLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Astoria';
    font-weight: 500;
    src: url('./fonts/AstoriaMedium.ttf') format('truetype');
}

@font-face {
    font-family: 'Astoria';
    font-weight: 600;
    src: url('./fonts/AstoriaBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Astoria';
    font-weight: 700;
    src: url('./fonts/AstoriaExtraBold.ttf') format('truetype');
}