$navbarSize: calc(min(300px, 30vw));
$navbarMobileSize: calc(min(100px, 15vw));

$orangeLight: #f1cbff;
$orangeBase: #703782;
$gray: #515151;
$green: #5aa03c;
$white: #fdfaff;
$whiteForm: #FFFEFA;

/*
$orangeLight: #FED281;
$orangeBase: #FDBB40;
$gray: #515151;
$white: #FFFBF0;
$whiteForm: #FFFEFA;
*/

* {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Astoria' !important;
}

.container-auth {
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding: 0;
}

.navbar-auth {
  height: 100vh;
  width: $navbarSize;
  position: fixed;
  left: 0;
  top: 0;
  font-size: 12px;
}

.navbar-footer {
  position: absolute;
  bottom: 0;
  z-index: 5;
  width: 100%;
  left: 0;
}

.content-auth {
  padding: 15px;
  min-height: 100vh;
  margin-left: $navbarSize;
  width: calc(100vw - min(100px, 7vw));
}

hr {
  border-top: 1px solid $gray;
}

.project-large-table {
  table-layout: fixed;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  max-height: 50vh;
  overflow: scroll !important;
  margin-top: 10px;

  td.sm, th.sm {
    width: 60px;
  }
  td.lg, th.lg {
    width: 180px;
  }
  td.xl, th.xl {
    width: 250px;
  }
  td.auto, th.auto {
    width: auto;
  }
  td, th {
    text-align: left;
    width: 80px;
    display: inline-flex;
    padding: 3px;
  }
  th {
  }
  tbody {
    tr {
      padding-top: 1vh;
      &:hover {
        background-color: #EFF6FF;
      }
      td {
        border-top: 1px solid #DDD;
        border-left: 1px solid #DDD;
        border-right: 1px solid #DDD;
        border-bottom: 1px solid #DDD;
        &:hover {
          cursor: pointer;
          text-align: left;
          background-color: #DBEAFE;
        }
      }
    }
  }
  &.sm {
    table-layout: auto;
    display: table;
    margin: 0 auto;
    margin-top: 0 !important;
  }
}

.btn-link-primary {
  @apply text-base p-1 m-1 px-2 bg-white text-indigo-700 border rounded border-indigo-700 transition hover:text-blue-700 hover:shadow hover:bg-gray-100;
}

.btn-link-alternate {
  @apply text-base p-1 m-1 px-2 bg-white text-gray-700 border rounded border-gray-700 transition hover:text-blue-700 hover:shadow hover:bg-gray-100;
}

.btn-link-danger {
  @apply text-base p-1 m-1 px-2 bg-white text-pink-600 border rounded border-red-700 transition hover:text-blue-700 hover:shadow hover:bg-gray-100;
}

.btn-detail-primary {
  @apply text-base p-1 m-1 px-2 bg-gray-600 rounded text-white hover:bg-indigo-500 transition duration-300 ease-in-out hover:shadow-lg shadow outline-none;
}

.btn-detail-alternate {
  @apply text-base p-1 m-1 px-2 bg-purple-900 rounded text-white hover:bg-gray-500 transition duration-300 ease-in-out hover:shadow-lg shadow outline-none;
}

.btn-detail-neutral {
  @apply text-base p-1 px-2 bg-gray-600 rounded text-white hover:bg-indigo-500 transition duration-300 ease-in-out hover:shadow-lg shadow outline-none;
}

.btn-detail-danger {
  @apply text-base p-1 m-1 px-2 bg-red-900 rounded text-white hover:bg-gray-500 transition duration-300 ease-in-out hover:shadow-lg shadow outline-none;
}

.btn-detail-warning {
  @apply text-lg p-1 m-1 px-2 bg-yellow-600 rounded text-white hover:bg-gray-500 transition duration-300 ease-in-out hover:shadow-lg shadow outline-none;
}

.order {
  @apply border shadow p-1 text-lg rounded;
}

.modal-lg {
  width: 100%;
  position: fixed;
  height: 100vh;
  background-color: rgba(0,0,0,0.6);
  padding: 5vh 5vw;
  z-index: 99999;
  top: 0;
  left: 0;

  .modal-lg-container {
    width: 90vw;
    max-width: 1192px;
    margin: 0 auto;
    background-color: $white;
    @apply shadow rounded p-4;
    max-height: 90vh;
    position: relative;
    //overflow-y: scroll;

    .modal-lg-close {
      position: absolute;
      right: 0;
      top: 0;
      @apply p-1 text-gray-400 font-normal text-2xl cursor-pointer transition;
      &:hover {
        @apply text-blue-600;
      }
    }
  }
  &.show {
    display: block;
  }
  &.hidden {
    display: none;
  }
}

.link-sm-indigo {
  @apply text-indigo-700 text-lg hover:text-blue-700 transition;
}

.alert-modal { 
  width: 100%;
  position: fixed;
  height: 100vh;
  background-color: rgba(0,0,0,0.6);
  z-index: 99999;
  top: 0;
  left: 0;
  padding: 35vh 15vw 0 15vw;
  
  .alert-container {
    width: 100%;
    margin: 0 auto;
    max-width: 360px;  
  }
}

.print-area {
  width: 100%;
}

.bg-ambra-gray {
  background-color: $gray;
}

button.bg-ambra-gray {
  background-color: $orangeBase;
  font-weight: 500;
}

.bg-ambra-white {
  background-color: $white;
}

.bg-ambra-whiteForm {
  background-color: $whiteForm;
}

.bg-ambra-orange {
  background-color: $orangeBase;
}

.bg-ambra-orangeLight {
  background-color: $orangeLight;

}

.border-ambra-gray {
  border-color: $gray;
}

.color-ambra-gray {
  color: $gray;
}

.color-ambra-white {
  color: $white;
}

.color-ambra-orange {
  color: $orangeBase;
}

.color-ambra-orange-light {
  color: $orangeLight;
}

.text-ambra-white {
  color: $white;
}

.login-title {
  background-color: rgba(81,81,81,0.7);
  line-height: 45px;
  font-size: 36px;
  font-weight: 700;
  color: $white;
  border-left: 4px solid $orangeLight;
  margin-bottom: 20px;
  max-width: 80%;
  margin-top: 60vh;
}

.login-subtitle {
  max-width: 80%;
  line-height: 28px;
  font-size: 20px;
  color: $white;
  font-weight: normal;
  text-align: justify;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.6);
}

.ambra-table {
  
}

@media print {

  body {
    background-color: #FFF !important;
  }

  .right-spacer {
    margin-right: 30px;
  }

  .print-size-1 {
    font-size: 10px;
  }
  .print-size-2 {
    font-size: 12px;
  }
  .print-size-3 {
    font-size: 14px;
  }
  .print-size-4 {
    font-size: 18px;
  }
  .print-size-5 {
    font-size: 24px;
  }

  .w-150 {
    width: 150px;
    height: 30px;
    border: 1px solid #000;
    margin-top: 15px;
  }
  .w-200 {
    width: 200px;
    height: 30px;
    border: 1px solid #000;
    margin-top: 15px;
  }
  .w-250 {
    width: 250px;
    height: 30px;
    border: 1px solid #000;
    margin-top: 15px;
  }
  .w-300 {
    width: 300px;
    height: 30px;
    border: 1px solid #000;
    margin-top: 15px;
  }
  .w-350 {
    width: 350px;
    height: 30px;
    border: 1px solid #000;
    margin-top: 15px;
  }
  .w-400 {
    width: 400px;
    height: 30px;
    border: 1px solid #000;
    margin-top: 15px;
  }
  .w-450 {
    width: 450px;
    height: 30px;
    border: 1px solid #000;
    margin-top: 15px;
  }
  .w-500 {
    width: 500px;
    height: 30px;
    border: 1px solid #000;
    margin-top: 15px;
  }
  .w-550 {
    width: 550px;
    height: 30px;
    border: 1px solid #000;
    margin-top: 15px;
  }
  .w-600 {
    width: 600px;
    height: 30px;
    border: 1px solid #000;
    margin-top: 15px;
  }
  .w-650 {
    width: 650px;
    height: 30px;
    border: 1px solid #000;
    margin-top: 15px;
  }

  table {
    //border: 1px solid #000;
    font-size: 0.12in !important;
    border: 0;

    thead {
      tr {
        //border-bottom: 1px solid #000;
        th {
          padding: 2px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 2px;
        }
        //border-top: 1px solid #000;
      }
    }
  }

  .title {
    font-size: 0.15in;
  }

  .font-family-monospace {
    font-family: monospace !important;
  }

  .font-family-sans-serif {
    font-family: sans-serif !important;
  }

  .font-family-system-ui {
    font-family: system-ui !important;
  }

  .regular {
    font-weight: 400;
  }
  .medium {
    font-weight: 500;
  }
  .semibold {
    font-weight: 600;
  }
  .bold {
    font-weight: 700;
  }
}

@media screen and (max-width: 1024px) {
  .navbar-auth {
    height: 100vh;
    width: $navbarMobileSize;
    position: fixed;
    left: 0;
    top: 0;
    color: #FFF;
    font-size: 10px;
    ul {
      li {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      }
    }
  }
  
  .content-auth {
    padding: 15px;
    min-height: 100vh;
    margin-left: $navbarMobileSize;
    width: calc(100vw - min(100px, 15vw));
  }
  
  .table-container {
    overflow-x: auto !important;
  }
}